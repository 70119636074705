import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ProjectIndexPage from "../../components/ProjectIndexPage"

const projectsQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { pageType: { eq: "project" } }
        fields: { langKey: { eq: "lv" } }
      }
    ) {
      edges {
        node {
          ...ProjectFragment
        }
      }
    }
  }
`

const LocalizedProjectIndexPage = ({ location }) => {
  const data = useStaticQuery(projectsQuery)
  return <ProjectIndexPage location={location} data={data} />
}

export default LocalizedProjectIndexPage
